<template>
  <div class="animated">
    <b-card>
      <div slot="header">
        <i class="fa fa-check"></i> Tags
      </div>
      <b-button variant="success" v-on:click="openCreateModal">
        <font-awesome-icon icon="plus" class="mr-2" />Create new tag
      </b-button>
      <hr />
      <div v-if="loading">
        <div class="sk-circle-fade float-left">
          <div class="sk-grid-cube"></div>
          <div class="sk-grid-cube"></div>
          <div class="sk-grid-cube"></div>
          <div class="sk-grid-cube"></div>
          <div class="sk-grid-cube"></div>
          <div class="sk-grid-cube"></div>
          <div class="sk-grid-cube"></div>
          <div class="sk-grid-cube"></div>
          <div class="sk-grid-cube"></div>
        </div>
        <h2 class="float-left ml-4">Loading tags...</h2>
      </div>
      <v-client-table
        :columns="columns"
        :data="data"
        :options="options"
        :theme="theme"
        id="dataTable"
        style="width: 100%; margin: none"
        v-else
      >
        <p
          slot="content"
          class="d-none d-md-inline-block"
          slot-scope="props"
          style="max-width: 780px !important"
          block
        >
          {{props.row.content.substring(0, 1050)}}
          <b-badge
            v-if="Object.keys(JSON.parse(props.row.embed)).length > 1"
            class="ml-2"
            variant="primary"
            pill
          >embed</b-badge>
        </p>
        <b-button
          slot="actions"
          slot-scope="props"
          block
          variant="outline-primary"
          v-on:click="openEditModal(props.row)"
        >Edit</b-button>
      </v-client-table>
    </b-card>

    <b-modal size="lg" v-model="largeModal" @ok="largeModal = false" :no-enforce-focus="true">
      <template slot="modal-title">
        <h4 class="smalltitle">
          <i class="icon-tag" style="margin-right:10px"></i>Edit tag
        </h4>
      </template>
      <b-form-group>
        <h4 class="smalltitle">Name</h4>
        <b-input-group>
          <div class="input-group-prepend">
            <div class="input-group-text">!</div>
          </div>
          <b-form-input
            type="text"
            pattern="[a-z0-9-_]{1,50}"
            class="col-12"
            :disabled="true"
            v-model="tag_name"
          ></b-form-input>
        </b-input-group>
        <hr />
        <h4 class="smalltitle">Content</h4>
        <b-input-group>
          <b-form-textarea
            rows="6"
            type="text"
            pattern="[a-z0-9-_]{1,5000}"
            placeholder="Enter some tag content"
            class="col-12"
            v-model="tag_content"
          ></b-form-textarea>
        </b-input-group>
        <hr />
        <b-row>
          <b-col>
            <h6 class="teenytinytitle">
              Restricted
              <c-switch
                class="float-right"
                id="restrict_toggle_button"
                color="info"
                label
                variant="pill"
                v-bind="labelIcon"
                v-model="tag_restricted"
              />
            </h6>
            <label
              style="color:#a8b6bd"
              for="restrict_toggle_button"
            >Restricted tags make use of the bot channel and redirects its output to the specified channel if used by a non-mod.</label>
            <hr />

            <h6 class="teenytinytitle">
              NSFW
              <c-switch
                class="float-right"
                id="nsfw_toggle_button"
                color="danger"
                label
                variant="pill"
                v-bind="labelIcon"
                v-model="tag_nsfw"
              />
            </h6>
            <label
              style="color:#a8b6bd"
              for="nsfw_toggle_button"
            >NSFW tags can only be used in channels marked as NSFW.</label>
          </b-col>
        </b-row>
        <hr />
        <EmbedBuilder ref="embed_builder"></EmbedBuilder>
      </b-form-group>
      <div slot="modal-footer" class="w-100">
        <b-container fluid class="text-light text-center" style="padding-right:0px;">
          <b-row class="mr-auto">
            <b-col cols="auto" class="mr-auto p-2">
              <b-button
                v-b-tooltip.hover
                title="Delete tag"
                variant="ghost-danger"
                v-on:click="deleteTag"
              >
                <i class="icon-trash"></i>
              </b-button>
            </b-col>
            <b-col cols="auto" class="p-2">
              <b-button
                v-b-tooltip.hover
                title="Create a shareable link"
                variant="ghost-primary"
                v-on:click="shareTag"
              >
                <i class="fas fa-share-square"></i>
              </b-button>
            </b-col>
            <b-col cols="auto" class="p-2">
              <b-button variant="ghost-secondary" v-on:click="largeModal = false">Cancel</b-button>
            </b-col>
            <b-col cols="auto" class="p-2">
              <b-button variant="primary" v-on:click="editTag">Save</b-button>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-modal>
    <b-modal
      title="Create tag"
      size="lg"
      v-model="createModal"
      @ok="xd = false"
      :no-enforce-focus="true"
    >
      <template slot="modal-title">
        <h4 class="smalltitle">
          <i class="icon-tag" style="margin-right:10px"></i>Create tag
        </h4>
      </template>
      <b-form-group>
        <h4 class="smalltitle">Name</h4>
        <b-input-group>
          <div class="input-group-prepend">
            <div class="input-group-text">!</div>
          </div>
          <b-form-input type="text" pattern="[a-z0-9-_]{1,50}" class="col-12" v-model="tag_name"></b-form-input>
        </b-input-group>
        <hr />
        <h4 class="smalltitle">Content</h4>
        <b-input-group>
          <b-form-textarea
            rows="6"
            type="text"
            pattern="[a-z0-9-_]{1,5000}"
            placeholder="Enter some tag content"
            class="col-12"
            v-model="tag_content"
          ></b-form-textarea>
        </b-input-group>
        <hr />
        <b-row>
          <b-col>
            <h6 class="tinytitle">
              Restricted
              <c-switch
                class="float-right"
                id="restrict_toggle_button"
                color="info"
                label
                variant="pill"
                v-bind="labelIcon"
                v-model="tag_restricted"
              />
            </h6>
            <label
              style="color:#a8b6bd"
              for="restrict_toggle_button"
            >Restricted tags make use of the bot channel and redirects its output to the specified channel if used by a non-mod.</label>
            <hr />

            <h6 class="tinytitle">
              NSFW
              <c-switch
                class="float-right"
                id="nsfw_toggle_button"
                color="danger"
                label
                variant="pill"
                v-bind="labelIcon"
                v-model="tag_nsfw"
              />
            </h6>
            <label
              style="color:#a8b6bd"
              for="nsfw_toggle_button"
            >NSFW tags can only be used in channels marked as NSFW.</label>
          </b-col>
        </b-row>
        <hr />
        <EmbedBuilder ref="embed_builder2" v-model="tag_embed"></EmbedBuilder>
      </b-form-group>
      <div slot="modal-footer" class="w-100">
        <b-container fluid class="text-light text-center" style="padding-right:0px;">
          <b-row class="mr-auto">
            <b-col cols="auto" class="mr-auto p-2"></b-col>
            <b-col cols="auto" class="p-2">
              <b-button variant="ghost-secondary" v-on:click="createModal = false">Cancel</b-button>
            </b-col>
            <b-col cols="auto" class="p-2">
              <b-button variant="success" v-on:click="createTag">Create</b-button>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import { Switch as cSwitch } from "@coreui/vue";
import { ClientTable, Event } from "vue-tables-2";
import VueNotifications from "vue-notifications";
import miniToastr from "mini-toastr";
import EmbedBuilder from "@/views/oc/EmbedBuilder";

const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn"
};
miniToastr.init({
  types: toastTypes,
  timeout: 5000,
  style: {
    "mini-toastr__notification": {
      "margin-top": "200px"
    }
  }
});

function toast({ title, message, type, timeout, cb }) {
  return miniToastr[type](message, title, timeout, cb);
}

const toast_options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast
};
Vue.use(VueNotifications, toast_options);
Vue.use(ClientTable);

export default {
  name: "DataTable",
  components: {
    ClientTable,
    Event,
    cSwitch,
    EmbedBuilder
  },
  notifications: {
    added_tag: {
      type: VueNotifications.types.success,
      title: "Success!",
      message: "Tag created"
    },
    edited_tag: {
      type: VueNotifications.types.success,
      title: "Success!",
      message: "Tag edited"
    },
    deleted_tag: {
      type: VueNotifications.types.success,
      title: "Success!",
      message: "Tag deleted"
    },
    fill_in_fields: {
      type: VueNotifications.types.warn,
      title: "Uh oh",
      message: "Please fill in all fields"
    },
    no_spaces_thank_you: {
      type: VueNotifications.types.warn,
      title: "Bad tag",
      message:
        "Tags don't really work when they have spaces added, consider replacing them with dashes or underscores"
    }
  },
  data: function() {
    return {
      columns: ["name", "content", "uses", "actions"],
      data: [],
      xd: EmbedBuilder,
      options: {
        filterByColumn: true,
        columnsDisplay: {
          content: "not_mobile",
          uses: "not_mobile"
        },
        texts: {
          filter: "Filter",
          filterBy: "Filter by {column}"
        },
        headings: {
          name: "Name",
          code: "Content",
          uri: "View Record"
        },
        sortable: ["name", "content", "uses"],
        filterable: ["name", "content"],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort"
        },
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll"
        },
        childRow: false,
        highlightMatches: true
      },
      useVuex: false,
      theme: "bootstrap4",
      template: "default",
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      },
      largeModal: false,
      createModal: false,
      tag_name: "",
      tag_content: "",
      tag_restricted: false,
      tag_nsfw: false,
      tag_id: null,
      tag_embed: { fields: [], colors: { hex: "#4f545c" } },
      guild_id: this.$route.params.guild_id,
      loading: true
    };
  },
  beforeRouteUpdate(to, from, next) {
    this.axios
      .get("/api/v1/servers/" + this.$route.params.guild_id + "/tags")
      .then(r => {
        this.data = r.data;
      });
    next();
  },
  methods: {
    openEditModal(row) {
      this.tag_name = row.name.trim();
      this.tag_content = row.content.trim();
      this.tag_restricted = row.restricted;
      this.tag_nsfw = row.nsfw;
      this.largeModal = true;
      this.tag_id = row.id;
      this.modal_type = "edit";
      var embed_var = JSON.parse(row.embed);
      setTimeout(() => {
        this.$refs.embed_builder.apply_json(embed_var);
        this.$refs.embed_builder.show_embed = Object.keys(embed_var).length > 1;
      }, 20);
    },
    openCreateModal() {
      this.tag_name = "";
      this.tag_content = "";
      this.tag_restricted = false;
      this.tag_nsfw = false;
      this.createModal = true;
      this.tag_id = null;
      this.modal_type = "edit";
      setTimeout(() => {
        this.$refs.embed_builder2.resetEmbed();
        this.$refs.embed_builder2.show_embed = false;
      }, 20);
    },
    editTag() {
      if (
        !this.tag_name.trim() ||
        (!this.tag_content &&
          Object.keys(this.$refs.embed_builder.full_json).length < 2)
      ) {
        return this.fill_in_fields();
      }
      this.data.forEach(e => {
        if (e.name === this.tag_name) {
          e.content = this.tag_content;
          e.restricted = this.tag_restricted;
          e.nsfw = this.tag_nsfw;
          e.embed = JSON.stringify(this.$refs.embed_builder.full_json);
        }
      });
      this.patchTag();
      this.edited_tag();
    },
    createTag() {
      if (
        !this.tag_name.trim() ||
        (!this.tag_content &&
          Object.keys(this.$refs.embed_builder2.full_json).length < 2)
      ) {
        return this.fill_in_fields();
      }
      if (this.tag_name.indexOf(" ") !== -1) {
        return this.no_spaces_thank_you();
      }

      this.putTag().then(res => {
        this.added_tag();
        var found = false;
        this.data.forEach(e => {
          if (e.name === this.tag_name) {
            e.content = this.tag_content;
            e.restricted = this.tag_restricted;
            e.nsfw = this.tag_nsfw;
            e.embed = JSON.stringify(this.$refs.embed_builder2.full_json);
            found = true;
          }
        });
        if (!found) {
          this.data.push({
            id: res,
            uses: 0,
            name: this.tag_name,
            content: this.tag_content,
            restricted: this.tag_restricted,
            nsfw: this.tag_nsfw,
            embed: JSON.stringify(this.$refs.embed_builder2.full_json)
          });
        }
      });
    },
    patchTag() {
      this.axios
        .patch("/api/v1/servers/" + this.$route.params.guild_id + "/tags", {
          name: this.tag_name,
          content: this.tag_content,
          restricted: this.tag_restricted,
          nsfw: this.tag_nsfw,
          embed: this.$refs.embed_builder.full_json
        })
        .then(this.closeModals());
    },
    deleteTag() {
      for (let [i, e] of this.data.entries()) {
        if (e.name === this.tag_name) {
          this.data.splice(i, 1);
        }
      }
      this.axios
        .delete(
          "/api/v1/servers/" +
            this.$route.params.guild_id +
            "/tags/" +
            this.tag_id, {
              data: {
                name: this.tag_name
              }
            }
        )
        .then(this.closeModals());
      this.deleted_tag();
    },
    shareTag() {
      this.axios.post("/api/v1/sharedtags/" + this.tag_id).then(r => {
        if (r.status == 200) {
          this.$router.push("/t/" + this.tag_id);
        } else {
          this.fill_in_fields({
            message: "Could not share that tag, check the console."
          });
        }
      });
    },
    putTag() {
      this.closeModals();
      return this.axios
        .put("/api/v1/servers/" + this.$route.params.guild_id + "/tags", {
          name: this.tag_name,
          content: this.tag_content,
          restricted: this.tag_restricted,
          nsfw: this.tag_nsfw,
          author: this.$store.state.discord_user.id,
          embed: this.$refs.embed_builder2.full_json
        })
        .then(r => {
          return r.data.id;
        });
    },
    closeModals() {
      this.largeModal = false;
      this.createModal = false;
    }
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.axios
      .get("/api/v1/servers/" + this.$route.params.guild_id + "/tags")
      .then(r => {
        this.data = r.data;
      });
  },
  created: function() {
    this.$Progress.start();
    this.axios
      .get("/api/v1/servers/" + this.$route.params.guild_id + "/tags")
      .then(r => {
        this.data = r.data;
        this.loading = false;
        this.$Progress.finish();
      });
  }
};
</script>

<style lang="scss">
#dataTable {
  width: 95%;
  margin: 0 auto;

  .VuePagination {
    text-align: center;
    justify-content: center;
  }

  .vue-title {
    text-align: center;
    margin-bottom: 10px;
  }

  .VueTables__search-field {
    display: flex;
  }
  .VueTables__search-field input {
    margin-left: 0.25rem;
  }

  .VueTables__limit-field {
    display: flex;
  }

  .VueTables__limit-field select {
    margin-left: 0.25rem !important;
  }

  .VueTables__table th {
    text-align: center;
  }

  .VueTables__child-row-toggler {
    width: 16px;
    height: 16px;
    line-height: 16px;
    display: block;
    margin: auto;
    text-align: center;
  }

  .VueTables__child-row-toggler--closed::before {
    content: "+";
  }

  .VueTables__child-row-toggler--open::before {
    content: "-";
  }
}
</style>
<style src="spinkit/spinkit.min.css"></style>
